<template>
  <section class="normativa">
    <div class="container">
        <div class="titulos-iniciales ">
            <div class="titulo">
                <h1>Normativa: <span> DISPOSICIONES DE CARÁCTER GENERAL</span> </h1>
            </div>
          </div>
        <div class="row secd" >
            <div class="col-md-6">
                <div class="descargas">
                    <div class="descarga">
                        <div class="imnorv">
                            <img src="@/assets/pdf.png" alt="">
                        </div>
                        <div class="textos"> 
                            <h1>Disposiciones de Carácter General</h1>
                            <a class="descargabtn" href="~@/assets/documentos/disposicion" >
                                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M9,1V7H5L12,14L19,7H15V1H9M5,16V18H19V16H5M5,20V22H19V20H5Z" />
                                </svg>
                                <span>Descargar Archivo</span>    
                            </a>
                            <p class="fecha"><small>Fecha de actualizacion: 6 Junio 2022</small></p>
                        </div>
                    </div>
                    <div class="descarga">
                        <div class="imnorv">
                            <img src="@/assets/pdf.png" alt="">
                        </div>
                        <div class="textos"> 
                            <h1>Actualización de las DCG publicadas en el DOF el 2011</h1>
                            <a class="descargabtn" :href="`${publicPath}/DisposicionesGeneral.pdfs`" >
                                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M9,1V7H5L12,14L19,7H15V1H9M5,16V18H19V16H5M5,20V22H19V20H5Z" />
                                </svg>
                                <span>Descargar Archivo</span>    
                            </a>
                            <p class="fecha"><small>Fecha de actualizacion: 6 Junio 2022</small></p>
                        </div>
                    </div>
                    <div class="descarga">
                        <div class="imnorv">
                            <img src="@/assets/pdf.png" alt="">
                        </div>
                        <div class="textos"> 
                            <h1>Actualización de las DCG publicadas en el DOF el 31-12-2014</h1>
                            <a class="descargabtn" href="~@/assets/documentos/disposicion" >
                                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M9,1V7H5L12,14L19,7H15V1H9M5,16V18H19V16H5M5,20V22H19V20H5Z" />
                                </svg>
                                <span>Descargar Archivo</span>    
                            </a>
                            <p class="fecha"><small>Fecha de actualizacion: 6 Junio 2022</small></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="descargas">
                    <div class="descarga">
                        <div class="imnorv">
                            <img src="@/assets/pdf.png" alt="">
                        </div>
                        <div class="textos"> 
                            <h1>Actualización de las DCG publicadas en el DOF el 09-03-2017</h1>
                            <a class="descargabtn" href="~@/assets/documentos/disposicion" >
                                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M9,1V7H5L12,14L19,7H15V1H9M5,16V18H19V16H5M5,20V22H19V20H5Z" />
                                </svg>
                                <span>Descargar Archivo</span>    
                            </a>
                            <p class="fecha"><small>Fecha de actualizacion: 6 Junio 2022</small></p>
                        </div>
                    </div>
                    <div class="descarga">
                        <div class="imnorv">
                            <img src="@/assets/pdf.png" alt="">
                        </div>
                        <div class="textos"> 
                            <h1>Actualización de las DCG publicadas en el DOF el 21-03-2019</h1>
                            <a class="descargabtn" href="~@/assets/documentos/disposicion" >
                                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M9,1V7H5L12,14L19,7H15V1H9M5,16V18H19V16H5M5,20V22H19V20H5Z" />
                                </svg>
                                <span>Descargar Archivo</span>    
                            </a>
                            <p class="fecha"><small>Fecha de actualizacion: 6 Junio 2022</small></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style>
.normativa{
    margin: 3rem 0;
}
.secd{
    margin: 2rem 0;
}
.descargas .descarga img{
    width: 80px ;
}
.descargas .descarga{
    display: flex;
    margin-bottom: 3rem;
    padding-left: 1rem;
    border-left: 5px solid #006699;
}
.descargas .descarga h1{
    font-size: 20px;
}
.descargas .descarga .textos{
   padding: 0rem 2rem;
}
.descargas .descarga .textos h1{
   color:#006699;
   margin-bottom: 15px;
}
.descargas .descarga .textos a{
   color:#006699;
   text-decoration: none;
   padding: 5px 0;
   border: none;
   border-width: 0;
   transition: border 2s ease;
 
}.descargas .descarga .textos a svg{
    margin-right: 10px;
}
.descargas .descarga .textos p{
   color:#757575;
   font-size: 9pt;
   margin-top: 10px;
   text-decoration: none;
}
.descargas .descarga .textos a:hover{
    border-bottom: 0.5px solid #555;
    border-bottom-width: 100%;
}
</style>