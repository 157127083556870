<template>
  <section class="fin">
      <div class="container">
          <div class="recuadro row">
              <div class="col-md-8">
                  <h1>¡Gracias por tu registro!</h1>
                  <p>Pronto uno de nuestro ejecutivos se pondrá en contacto contigo</p>
                  <router-link to="/"><button>Volver al inicio</button></router-link>
              </div>
              <div class="col-md-4 icono" >
                  <div class="icon">
                      <svg  viewBox="0 0 24 24">
                            <path fill="currentColor" d="M23,10C23,8.89 22.1,8 21,8H14.68L15.64,3.43C15.66,3.33 15.67,3.22 15.67,3.11C15.67,2.7 15.5,2.32 15.23,2.05L14.17,1L7.59,7.58C7.22,7.95 7,8.45 7,9V19A2,2 0 0,0 9,21H18C18.83,21 19.54,20.5 19.84,19.78L22.86,12.73C22.95,12.5 23,12.26 23,12V10M1,21H5V9H1V21Z" />
                      </svg>
                  </div>
              </div>
          </div>
      </div>
  </section>
</template>

<script>
export default {

}
</script>

<style lang="css">
.fin {
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.recuadro {
  background-color: #00669916;
  border-radius: 15px;
  margin: auto;
  padding: 5rem;
  box-shadow: 1px 1px 20px 1px #1a2b3342;
}
.recuadro h1 {
  color: #006699;
  font-weight: 700;
}
.recuadro p {
  margin-bottom: 1.5rem;
}
.recuadro .icono {
  display: flex;
  justify-content: center;
  align-items: center;
}
.recuadro .icono .icon {
  background-color: #006699;
  padding: 3rem;
  border-radius: 50%;
}
.recuadro .icono .icon svg {
  width: 3rem;
  color: #fff;
}
.recuadro button {
  padding: 1rem 2rem;
  border: none;
  color: #006699;
  background: #fff;
  font-weight: 700;
  box-shadow: 1px 1px 5px #75757573;
}
@media only screen and (max-width: 768px) {
  .recuadro {
    padding: 2rem;
  }
  .recuadro .icono {
    margin-top: 2rem;
  }
  .recuadro .icono .icon {
    padding: 2rem;
  }
  .recuadro .icono .icon svg {
    width: 2rem;
  }
}

</style>