<template>
  <section class="banner-brokers">
     <div class="container">
         <div class="row justify-content-center">
             <div class="col-md-5">
                 <img width="100%" height="450px" style="object-fit:cover" src="https://www.credix.com.mx/plantillav2/images/empresariales/reducidas/shutterstock_386549827.jpg" alt="">
             </div>
             <div class="col-md-7 titulos">
                 <h1>Únete a nuestro gran equipo de Brókers.</h1>
                 <h2>Estamos buscando alianzas que nos permitan seguir creciendo y tú puedes ser parte de esto.</h2>
                 <button class="btn-credix">Postularse como bróker</button>
             </div>
            
         </div>
     </div>
  </section>
</template>

<script>
export default {

}
</script>

<style lang="css">
    .banner-brokers {
  height: 70vh;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 3rem 0;
}
.banner-brokers .titulos {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  color: #3b3b3b;
}
.banner-brokers .titulos h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}
.banner-brokers .titulos h2 {
  font-size: 1rem;
  width: 80%;
  margin-bottom: 2rem;
}

</style>