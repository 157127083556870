import Acceso from '@/views/acceso/Acceso.vue'
import Inicio from '@/views/acceso/Inicio.vue'
import RegistroP from '@/views/acceso/RegistroProv.vue'
import Financiamiento from '@/views/acceso/Financiamiento.vue'
import AltaClientes from '@/views/acceso/AltaClientes.vue'
import Recuperar from '@/views/acceso/recuperar.vue'
import Prov from '@/views/acceso/Prov.vue'
export default [
    {
        path:'/iniciar-sesion',
        component: Acceso,
        children:[
            {
                path:"/iniciar-sesion",
                component: Inicio
            },
            {
                path:"/iniciar-sesion-proveedor",
                component: Prov
            },
            {
                path:"/registro-proveedor",
                component: RegistroP
            },
            {
                path:"/solicita-tu-financiamiento",
                component:Financiamiento
            },
            {
                path:'/alta-clientes',
                component:AltaClientes
            },
            {
                path:'/recuperar-contrasena',
                component:Recuperar
            },
        ]
    }
]