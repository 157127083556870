<template>
<div>
 
  <div class="loading">
    <p>Simulando</p>
    <div class="dot-flashing"></div>
  </div>
</div>

</template>

<script >
export default {
  props:{
    textosP: {
      type: Number,
      default: 1
    },
  },
  data(){
    return {
      texto:[]
    }
  },
  
  mounted(){ 
   
  }
}
</script>

<style lang="css">
.blink{
  color: #006699;
  text-align: center;
}
.loading{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3rem 0;
}
.loading p{ 
    color: #006699;
}
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #006699;
  color: #006699;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #006699;
  color: #006699;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #006699;
  color: #006699;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}
.typicalWrapper::after {
  content: "|";
  animation: blink 1s infinite step-start;
}

@keyframes blink {
  50% { opacity: 0; }
}

@keyframes dotFlashing {
  0% {
    background-color: #006699;
  }
  50%,
  100% {
    background-color: #8ed9ff;
  }
}

</style>