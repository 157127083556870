<template>
    <div>
        <Cargando texto="Redirigiendo..."/>
    </div>
</template>
<script>
import Cargando from '@/components/Loading/puntos2.vue'
    export default {
        components:{
            Cargando
        },
        mounted(){
            if(localStorage.getItem("regApp")){
                if(this.$route.query.salir){
                    localStorage.removeItem("regApp")
                }
                location.href = '/'
            }else{
                location.href = '/'
            }
        }
    }
</script>