<template>
  <section class="fin">
      <div class="container">
          <div class="recuadro row">
              <div class="col-md-8">
                  <h1>¡Lo sentimos!</h1>
                  <p>Con base a tu perfil creemos que no eres apto para solicitar un financiamiento con nosotros</p>
                  <router-link to="/"><button>Volver al inicio</button></router-link>
              </div>
              <div class="col-md-4 icono" >
                  <div class="icon">
                      <svg  viewBox="0 0 24 24">
    <path fill="currentColor" d="M19,15H23V3H19M15,3H6C5.17,3 4.46,3.5 4.16,4.22L1.14,11.27C1.05,11.5 1,11.74 1,12V14A2,2 0 0,0 3,16H9.31L8.36,20.57C8.34,20.67 8.33,20.77 8.33,20.88C8.33,21.3 8.5,21.67 8.77,21.94L9.83,23L16.41,16.41C16.78,16.05 17,15.55 17,15V5C17,3.89 16.1,3 15,3Z" />
</svg>
                  </div>
              </div>
          </div>
      </div>
  </section>
</template>

<script>
export default {

}
</script>

<style lang="css">
.fin {
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.recuadro {
  background-color: #00669916;
  border-radius: 15px;
  margin: auto;
  padding: 5rem;
  box-shadow: 1px 1px 20px 1px #1a2b3342;
}
.recuadro h1 {
  color: #006699;
  font-weight: 700;
}
.recuadro p {
  margin-bottom: 1.5rem;
}
.recuadro .icono {
  display: flex;
  justify-content: center;
  align-items: center;
}
.recuadro .icono .icon {
  background-color: #006699;
  padding: 3rem;
  border-radius: 50%;
}
.recuadro .icono .icon svg {
  width: 3rem;
  color: #fff;
}
.recuadro button {
  padding: 1rem 2rem;
  border: none;
  color: #006699;
  background: #fff;
  font-weight: 700;
  box-shadow: 1px 1px 5px #75757573;
}
@media only screen and (max-width: 768px) {
  .recuadro {
    padding: 2rem;
  }
  .recuadro .icono {
    margin-top: 2rem;
  }
  .recuadro .icono .icon {
    padding: 2rem;
  }
  .recuadro .icono .icon svg {
    width: 2rem;
  }
}

</style>