<template>
    <div>
        <Header/>
            <router-view/>
        <Footer/>
    </div>
</template>

<script>
import Header from '@/components/Home/Header.vue'
import Footer from '@/components/Home/Footer.vue'
export default {
  data(){
    return{

    }
  },
  components:{
    Header,
    Footer
  }
}
</script>


