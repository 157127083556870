<template>
  <section>
      <div class="container">
          <div class="row">
              <div class="col-md-4 bg-info">

              </div>
              <div class="col-md-8">

              </div>
          </div>
      </div>
  </section>
</template>

<script>
export default {

}
</script>

<style>

</style>