<template>
  <transition name="modal">
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">

        <div class="modal-header">
          <slot name="header">
            default header
          </slot>
        </div>

        <div class="modal-body">
          <slot name="body">
            default body
          </slot>
        </div>

        <div class="modal-footer">
          <slot name="footer">
            <button class="modal-default-button" @click="$emit('close')">
              Cerrar
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</transition>
</template>

<script>
export default {

}
</script>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 90%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3,.modal-body h3 {
  margin-top: 0;
  color: #336699;
 
}

.modal-body h3 {
 font-weight: 300;
}
.modal-body {
  margin: 20px 0;
  overflow: auto;
  height: 50vh;
  text-align: justify;
}
.modal-body a{
    color: #006699;
    font-weight: bold;
}

.modal-default-button {
  float: right;
}
.modal-default-button{
    background:#006699;
    color:#fff;
    border-radius: 10px;
    border: none;
    padding: 1rem 4rem;
    outline: none;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>