<template>
  <section class="aviso">
      <div class="container">   
        <div class="titulos-iniciales">
            <div class="titulo">
                <h1>Aviso de <span>Privacidad</span> </h1>
            </div>
          </div>
          <h6>Actualizacion: 04 de septiembre del 2023</h6>

                        <div class="row">

                            <div class="col-md-12 col-sm-12 col-lg-12">

                                

                                                

                                                

		<p itemprop="description"></p><p style="color:#000; font-size:18px; text-align:justify;"><span style="color:#006699; font-weight:700">CREDIX GS</span> S.A. de C.V. SOFOM E.N.R., con domicilio social en Periférico Paseo de la República número 2650 Piso 4, en la Colonia Prados del Campestre de la ciudad de Morelia, Michoacán, Código Postal 58297.
		<br>                            
		<br>
		En cumplimiento a lo dispuesto a la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en lo sucesivo LFPDPPP), y su reglamento hace de su conocimiento que la información recabada por <span style="color:#006699; font-weight:700">CREDIX</span> de nuestros clientes es tratada de forma estrictamente confidencial y estamos comprometidos con la protección de sus datos personales, para efectos de asumir la responsabilidad del uso, manejo y confidencialidad, pone a disposición el presente <span style="color:#006699; font-weight:700"><span style="color:#006699; font-weight:700">aviso de privacidad</span></span> en los términos siguientes:
		<br>
		<br>
		<span style="color:#006699; font-weight:700">1.	Datos Personales Recabados.</span>
		<br>
		<br>
		Para cumplir con las finalidades previstas en el presente <span style="color:#006699; font-weight:700">aviso de privacidad</span>, <span style="color:#006699; font-weight:700">CREDIX</span> recabará la siguiente información:
		<br>
		<br>
		•	Datos de Identificación: Nombre, apellidos, género, fecha de nacimiento, entidad, país de nacimiento, nacionalidad, número del documento con el que se identifica, domicilio actual, ocupación, Clave Única del Registro de Población (CURP), firma autógrafa, estado civil, datos del cónyuge en caso de aplicar, nombre completo del beneficiario o fideicomisario en segundo lugar sustituto y todos aquellos que el cliente decida libremente compartir a <span style="color:#006699; font-weight:700">CREDIX</span>;
		<br>
		•	Datos de Contacto: Teléfono fijo, teléfono celular, correo electrónico, datos de contacto de referencias personales y todos aquellos que el cliente decida libremente compartir a <span style="color:#006699; font-weight:700">CREDIX</span>;
		<br>
		•	Datos Laborales: Actividad profesional o giro del negocio al que se dedique y datos de la misma naturaleza y exclusivamente bajo esta categoría y todos aquellos que el cliente decida libremente compartir a <span style="color:#006699; font-weight:700">CREDIX</span>;
		<br>
		•	Datos Patrimoniales: Historial crediticio, CLABE interbancaria, estados de cuenta bancarios, Registro Federal de Contribuyentes (RFC), número de serie FIEL, información financiera o patrimonial relacionada con bienes muebles o inmuebles propiedad del cliente y todos aquellos que el cliente decida libremente compartir a <span style="color:#006699; font-weight:700">CREDIX</span>;
		<br>
		•	Datos Sensibles: Es importante mencionar que <span style="color:#006699; font-weight:700">CREDIX</span> no recaba datos personales sensibles de los clientes, a menos que sean necesarios para algún trámite, en el cual sean requeridos, así mismo <span style="color:#006699; font-weight:700">CREDIX</span> informará de dicha situación, cabe aclarar que en caso de tener estos datos se dará un tratamiento de estricta confidencialidad.
		<br>
		<br>
		<span style="color:#006699; font-weight:700">CREDIX</span> tratará sus datos personales únicamente por el tiempo que sea necesario a fin de cumplir con las finalidades descritas en el presente <span style="color:#006699; font-weight:700">aviso de privacidad</span> y/o de conformidad con lo que establezcan las disposiciones legales aplicables.
		<br>
		<br>
		<span style="color:#006699; font-weight:700">CREDIX</span> podrá recabar los datos personales del titular de alguna de las siguientes formas:
		<br>
		<br>
		•	Cuando el titular los proporcione personal o directamente.
		<br>•	Cuando el titular utilice el sitio de internet de <span style="color:#006699; font-weight:700">CREDIX</span>.
		<br>•	Cuando se obtengan por <span style="color:#006699; font-weight:700">CREDIX</span> a través de las fuentes permitidas por la legislación.
		<br>
		<br>
		Como parte del proceso de alta de clientes y/o de la relación jurídica existente o pasada, <span style="color:#006699; font-weight:700">CREDIX</span> podrá solicitarle y/o conservará copias de la documentación en donde consten o relacionada con los Datos Personales, misma que será igualmente resguardada con las medidas de seguridad técnicas, administrativas y físicas necesarias para proteger sus Datos Personales.
		<br>
		<br>
		Datos Personales de Terceros.
		<br>
		<br>
		Para cumplir con las obligaciones legalmente adquiridas con usted, <span style="color:#006699; font-weight:700">CREDIX</span> tratará datos personales de identificación y de contacto de terceros que nos serán proporcionados por usted, en calidad de referencias personales, por lo que usted se obliga a informar a dichos terceros sobre el tratamiento de sus datos personales y el contenido de este <span style="color:#006699; font-weight:700">aviso de privacidad</span>.
		<br>
		<br>
		<span style="color:#006699; font-weight:700">2.	Finalidad del Tratamiento de los Datos.</span>
		<br>
		<br>
		<span style="color:#006699; font-weight:700">CREDIX</span> requiere de los datos personales, para ser utilizados en y/o para las siguientes finalidades: 
		<br>
		<br>
		•	Finalidades Primarias; Son aquellas que dan origen o que son necesarias para la consecución y cumplimiento de una relación jurídica (potencial, presente o pasada) entre usted y <span style="color:#006699; font-weight:700">CREDIX</span>, es decir, para:
		<br>
		<br>i)	Verificar, confirmar y validar la identidad del Titular, así como de las referencias que señale;
		<br>ii)	Consultar su historial crediticio;
		<br>iii)	Administrar, proveer, operar y dar seguimiento a los servicios que solicita o contrata con <span style="color:#006699; font-weight:700">CREDIX</span>;
		<br>iv)	Formalizar la relación contractual entre <span style="color:#006699; font-weight:700">CREDIX</span> y el Titular;
		<br>v)	Llevar a cabo el alta, renovación o baja de cliente en los sistemas de <span style="color:#006699; font-weight:700">CREDIX</span>, incluyéndolo en nuestra base de datos e integrando los expedientes de identificación para el seguimiento del cliente; 
		<br>vi)	La contratación de seguros por <span style="color:#006699; font-weight:700">CREDIX</span> y/o por el Cliente que sean obligatorios y/o relacionados con el o los productos o servicios otorgados por <span style="color:#006699; font-weight:700">CREDIX</span>; 
		<br>vii)	Enviar comunicaciones relacionadas con los servicios contratados, así como estados de cuenta e información correspondiente al manejo de los productos y servicios que contrate de <span style="color:#006699; font-weight:700">CREDIX</span>;
		<br>viii)	Llevar a cabo los cobros correspondientes por los servicios proporcionados por <span style="color:#006699; font-weight:700">CREDIX</span> y cualesquier gestiones de cobro por adeudos pendientes de pago;
		<br>ix)	La gestión adicional relacionada con el otorgamiento del crédito, administración, cobranza, cesiones a cargo del Cliente y a favor de <span style="color:#006699; font-weight:700">CREDIX</span>, y/o del préstamo, crédito, financiamiento y/o servicio financiero contratado por el Cliente con <span style="color:#006699; font-weight:700">CREDIX</span>; 
		<br>x)	Elaborar y/o modificar contratos, convenios, acuerdos comerciales y/o cualquier otro documento necesario para crear, complementar, cumplir o dar por terminada cualquier relación comercial, jurídica o de negocios que tenga usted con <span style="color:#006699; font-weight:700">CREDIX</span>; 
		<br>xi)	Brindarle seguridad y evitar posibles riesgos dentro de las instalaciones de <span style="color:#006699; font-weight:700">CREDIX</span>;
		<br>xii)	Realizar aclaraciones para prevenir y detectar fraudes o ilícitos en agravio de su persona, o bien, de <span style="color:#006699; font-weight:700">CREDIX</span>;
		<br>xiii)	Atender sus consultas, quejas, reclamaciones y/o sugerencias;
		<br>xiv)	Gestiones dirigidas a mantener actualizados, correctos y completos sus Datos Personales;
		<br>xv)	Realizar cualquier evaluación o análisis que sea requerido para la prestación de los servicios de <span style="color:#006699; font-weight:700">CREDIX</span>; y
		<br>xvi)	El cumplimiento de leyes, reglamentos y disposiciones legales aplicables a la relación jurídica del cliente con <span style="color:#006699; font-weight:700">CREDIX</span>.
		<br>
		<br>
		•	Finalidades Secundarias o Accesorias; Son aquellas que no son necesarias para la existencia y cumplimiento de la relación jurídica que el Titular tiene con el responsable, sin embargo, son complementarias e importantes, es decir, para:
		<br>
		<br>
		i.	Realizar encuestas de servicio, con el objetivo de evaluar y mejorar la calidad de los productos y servicios que ofrecemos.
		<br>ii.	Realizar actividades de mercadotecnia, publicidad, promoción y telemarketing, de los productos y servicios que son ofrecidos por <span style="color:#006699; font-weight:700">CREDIX</span>, por cualquier medio material y/o electrónico.
		<br>
		<br>
		Lo anterior, con independencia de que <span style="color:#006699; font-weight:700">CREDIX</span> haga de su conocimiento el presente <span style="color:#006699; font-weight:700">aviso de privacidad</span> al primer contacto que <span style="color:#006699; font-weight:700">CREDIX</span> tenga con los terceros titulares de los datos transferidos y previo al aprovechamiento de los mismos en virtud de haberlos obtenido de manera indirecta, debiendo tratar tales datos conforme al contenido de este <span style="color:#006699; font-weight:700">aviso de privacidad</span>.
		<br>
		<br>
		Usted es responsable de garantizar que los datos personales de familiares, beneficiarios, referencias y contactos personales, que transfiera a <span style="color:#006699; font-weight:700">CREDIX</span> sean veraces y completos, así como de notificar a dichos titulares la transmisión y tratamiento de datos a que se refiere el presente apartado.
		<br>
		<br>
		<span style="color:#006699; font-weight:700">3.	Limitación del Tratamiento de Datos Personales para Finalidades Secundarias.</span>
		<br>
		<br>
		En caso de no estar de acuerdo con las Finalidades Secundarias o No Necesarias, le informamos que usted contará con un plazo no mayor a 5 (cinco) días naturales contados a partir de la fecha en que sea puesto a su disposición el presente <span style="color:#006699; font-weight:700">aviso de privacidad</span> para externarnos su negativa, enviando un correo electrónico a la dirección <span style="color:#006699; font-weight:700">unes@credix.com.mx</span>. Quedando a salvo los derechos del Titular para ejercer sus derechos a la revocación del consentimiento u oposición, en caso de que no manifieste la negativa para el tratamiento de sus datos personales, previo a la entrega de los mismos o de su aprovechamiento. En caso de no recibir la solicitud correspondiente, se entenderá por consentido el uso de su información para todas las finalidades antes mencionadas.
		<br>
		<br>
		La negativa para el uso de sus datos personales para las finalidades secundarias no podrá ser un motivo para que le neguemos los servicios y productos que actualmente solicita o contrata con <span style="color:#006699; font-weight:700">CREDIX</span>.
		<br>
		<br>
		<span style="color:#006699; font-weight:700">4.	Transferencias de Datos Personales.</span>
		<br>
		<br>
		Las transferencias son comunicaciones de datos personales realizadas a otras empresas distintas a <span style="color:#006699; font-weight:700">CREDIX</span> o en su defecto, a subsidiarias, filiales o controladoras;
		<br>
		<br>
		<span style="color:#006699; font-weight:700">CREDIX</span> podrá revelar o transferir sus Datos Personales por requerimiento de la autoridad competente o en cualquier otro de los supuestos establecidos en los Artículos 10 y 37 de la LFPDPP, para lo cual no se requiere el consentimiento del Titular de dichos datos personales conforme a la Ley y su Reglamento.
		<br>
		<br>
		En este sentido, hacemos de su conocimiento que <span style="color:#006699; font-weight:700">CREDIX</span>, transfiere y/o transferirá sus datos personales en los siguientes casos:
		<br>
		<br>
		a)	Transferencias de datos personales que no requieren de autorización del Titular:
		<br>
		<table border="1">
		<tr><td width="50%" align="center"><b>Sector al que pertenece el receptor de los datos personales</b></td><td width="50%" align="center"><b>Finalidad</b></td></tr>
		<tr><td>Autoridades financieras y fiscales mexicanas</td>Para el cumplimiento de las obligaciones establecidas en la regulación financiera y fiscal aplicable.<td></td></tr>
		<tr><td>Personas morales del sector de servicios de cobranza y recuperación de cartera judicial y extrajudicial.</td><td>Para llevar a cabo la cobranza judicial y extrajudicial de los productos y servicios financieros contratados, así como para el envío masivo de mensajes de texto (SMS) personalizados y correos electrónicos para dichos fines.</td></tr>
		<tr><td>Personas morales del sector de servicios de automatización de información, comunicación masiva y tecnología.</td><td>Para envío masivo de mensajes de texto (SMS) personalizados y correos electrónicos relacionados con la administración del producto o servicio contratado.</td></tr>
		<tr><td>Instituto Nacional Electoral</td><td>para verificar y validar la autenticidad de los datos y documentos proporcionado por el Titular respecto de su identidad.</td></tr>
		<tr><td>Proveedores que presten servicios a <span style="color:#006699; font-weight:700">CREDIX</span> incluyendo servicios de auditoría externa</td><td>Para consulta de listas negras, dar atención a los requerimientos de auditoría, entre otros servicios de consultoría.</td></tr>
		<tr><td>otras instituciones financieras, personas morales y/o personas físicas.</td><td>cuando <span style="color:#006699; font-weight:700">CREDIX</span> ceda, transmita, transfiera, afecte, grave o negocie, en cualquier forma, los derechos de crédito del o los créditos que se registren en los contratos de apertura de crédito celebrados; o en los pagarés suscrito al amparo de los mismos por el Titular; o cuando <span style="color:#006699; font-weight:700">CREDIX</span> celebre fusiones o escisiones en las que el Titular se vea involucrado</td></tr>
		<tr><td>Nuestras subsidiarias, filiales o controladoras</td><td>para llevar a cabo la operación y administración de los créditos contratados, cuando <span style="color:#006699; font-weight:700">CREDIX</span> celebra fusiones o escisiones en las que dichas empresas se vean involucradas</td></tr>
		<tr><td>A la empresa que adquiera las obligaciones legales al llevar a cabo la unión o separación de <span style="color:#006699; font-weight:700">CREDIX</span>.</td><td>En caso de una fusión o escisión</td></tr>
		</table>
		<br><br>
		b)	Transferencias de datos personales que requieren de autorización del Titular:
		<br><br>
		<table border="1">
		<tr><td width="50%" align="center"><b>Sector al que pertenece el receptor de los datos personales</b></td><td width="50%" align="center"><b>Finalidad</b></td></tr>
		<tr><td>Personas morales del sector de servicios en consultoría, tecnología y evaluación de riesgos.</td><td>Para consultoría en investigación del comportamiento de usuarios usando técnicas de ciencias de la conducta.</td></tr>
		<tr><td>Personas morales del sector de servicios de marketing y ventas.</td><td>Para la gestión y administración de estrategias de marketing, (campañas digitales en redes sociales, landing pages, blogs, sitios web, formularios web, correos electrónicos, y plataforma de ventas).</td></tr>
		<tr><td>Personas morales del sector financiero.</td><td>Para ofrecer productos y servicios financieros, así como de promociones especiales.</td></tr>
		</table>
		<br><br>
		Si usted acepta o no la transferencia de sus datos, de igual manera, el tercero receptor, asumirá las mismas obligaciones que correspondan a <span style="color:#006699; font-weight:700">CREDIX</span> al transferir los datos.
		<br>
		<br>
		En caso de no estar de acuerdo con la transferencia de datos que requieren de autorización, le informamos que usted contará con un plazo no mayor a 5 (cinco) días naturales contados a partir de la fecha en que sea puesto a su disposición el presente <span style="color:#006699; font-weight:700">aviso de privacidad</span> para externarnos su negativa, enviando un correo electrónico a la dirección <span style="color:#006699; font-weight:700">unes@credix.com.mx</span>. Quedando a salvo los derechos del Titular para ejercer sus derechos a la revocación del consentimiento u oposición, en caso de que no manifieste la negativa para el tratamiento de sus datos personales, previo a la entrega de los mismos o de su aprovechamiento. En caso de no recibir la solicitud correspondiente, se entenderá por consentido el uso de su información para todas las finalidades antes mencionadas.
		<br>
		<br>
		La negativa para el uso de sus datos personales para las finalidades secundarias no podrá ser un motivo para que le neguemos los servicios y productos que actualmente solicita o contrata con <span style="color:#006699; font-weight:700">CREDIX</span>.
		<br>
		<br>
		<span style="color:#006699; font-weight:700">5.	Medidas de Seguridad y Control.</span>
		<br>
		<br>
		<span style="color:#006699; font-weight:700">CREDIX</span> ha adoptado las medidas de seguridad, administrativas, técnicas y físicas, necesarias para proteger sus datos personales contra daño, pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizado.
		<br>
		<br>
		En caso de vulneración de los datos personales, <span style="color:#006699; font-weight:700">CREDIX</span> informará inmediatamente al titular al correo electrónico que nos haya proporcionado.
		<br>
		<br>
		<span style="color:#006699; font-weight:700">6.	Ejercicio de Derechos ARCO y Revocación del Consentimiento.</span>
		<br>
		<br>
		Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada conforme los principios, deberes y obligaciones previstos en la normativa (Cancelación); así como oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO. De la misma forma usted puede revocar el consentimiento que, en su caso, nos haya otorgado y/o limitar el uso o divulgación de sus datos personales.
		<br>
		<br>
		Usted podrá ejercer en cualquier momento los derechos anteriormente citados, así como revocar el consentimiento otorgado para el tratamiento de sus datos personales de la siguiente manera:
		<br>
		<br>
		<span style="color:#006699; font-weight:700">1.	De forma personal o a través de su representante legal, deberá presentar una solicitud de ejercicio de derechos ARCO.</span>
		<br>
		<br>
		Dicha solicitud deberá contener:
		<br>
		<br>
		•	El nombre completo del Titular;
		<br>•	Copia de los documentos que acrediten la identidad del Titular de los datos personales (Identificación oficial vigente siguientes: Credencial para votar con fotografía, cedula profesional con fotografía y/o  Pasaporte) por ambos lados;
		<br>•	En caso de que el Titular comparezca por medio de representante legal, éste último deberá también exhibir, por la misma vía, los documentos que acrediten la representación legal (Poder notarial);
		<br>•	Descripción clara y precisa de los Datos Personales respecto de los que se busca ejercer alguno de los derechos ARCO;
		<br>•	Cualquier otro elemento o documento que facilite la localización de los Datos Personales;
		<br>•	Indicar las modificaciones a realizarse y/o las limitaciones al uso de los Datos Personales, y aportar la documentación que sustente su petición.
		<br>
		<br>
		<span style="color:#006699; font-weight:700">2.	conforme al procedimiento y requisitos siguientes:</span>
		<br>
		<br>
		•	Usted como titular de los datos personales o a través de su representante legal, deberá realizar la Solicitud para ejercer derechos ARCO, junto con la documentación requerida y enviarla por correo electrónico al Responsable de Datos Personales a la dirección <span style="color:#006699; font-weight:700">unes@credix.com.mx</span>. 
		<br>•	<span style="color:#006699; font-weight:700">CREDIX</span> acusará de recibido a través de un correo electrónico que se enviará a la dirección que se señale en la propia Solicitud para ejercer derechos ARCO.
		<br>•	<span style="color:#006699; font-weight:700">CREDIX</span> apegará su respuesta a los plazos que posteriormente se describen.
		<br>
		<br>
		Es importante que tenga en cuenta que no en todos los casos podremos darle una respuesta favorable a su Solicitud para ejercer derechos ARCO, debido a que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales.
		<br>
		<br>
		En el caso de que la información proporcionada en la Solicitud sea insuficiente o errónea para atenderla, o bien, no se acompañen los documentos necesarios, <span style="color:#006699; font-weight:700">CREDIX</span> podrá requerir al titular a través del correo electrónico que al efecto proporcione usted como solicitante, por una vez y dentro de los cinco días hábiles siguientes a la recepción de la Solicitud para ejercer derechos ARCO, que aporte los elementos o documentos necesarios para dar trámite a la misma. Usted contará con diez días hábiles para atender el requerimiento vía correo electrónico a la dirección <span style="color:#006699; font-weight:700">unes@credix.com.mx</span>,  contados a partir del día siguiente en que lo haya recibido. De no dar respuesta en dicho plazo, se tendrá por no presentada la Solicitud para ejercer derechos ARCO correspondiente.
		<br>
		<br>
		En caso de que usted atienda el requerimiento de información, el plazo para que <span style="color:#006699; font-weight:700">CREDIX</span> dé respuesta a la Solicitud para ejercer derechos ARCO empezará a correr al día siguiente de que usted haya atendido el requerimiento.
		<br>
		<br>
		<span style="color:#006699; font-weight:700">CREDIX</span> cuenta con un plazo máximo de 20 días hábiles contados desde la fecha en que se recibió la Solicitud para ejercer derechos ARCO para dar respuesta a la misma, a efecto de que, si resulta procedente, haga efectiva la misma dentro de los 15 días hábiles siguientes a que se comunique la respuesta. La respuesta se dará vía electrónica a la dirección de correo que se especifique en la Solicitud para ejercer derechos ARCO.
		<br>
		<br>
		Los plazos antes referidos podrán ser ampliados una sola vez por un periodo igual, siempre y cuando así lo justifiquen las circunstancias del caso. Los datos de contacto del área que dará trámite a las solicitudes para el ejercicio de los derechos ARCO, así como atender cualquier duda que pudiera tener respecto al tratamiento de su información son los siguientes: correo electrónico <span style="color:#006699; font-weight:700">unes@credix.com.mx</span>, teléfono 4432321000 ext. 158.
		<br>
		<br>
		<span style="color:#006699; font-weight:700">7.	Medios para Limitar el Uso o Divulgación de sus Datos Personales.</span>
		<br>
		<br>
		Con objeto de que usted pueda limitar el uso y divulgación de su información personal, le ofrecemos los siguientes medios:
		<br>
		<br>
		•	Su registro en el Listado de Exclusión de <span style="color:#006699; font-weight:700">CREDIX</span> a fin de que sus datos personales no sean tratados para fines mercadotécnicos, publicitarios o de prospección comercial por nuestra parte. Para mayor información llamar al número telefónico 443 2321000 ext. 158, enviar un correo electrónico a la siguiente dirección <span style="color:#006699; font-weight:700">unes@credix.com.mx</span>.
		<br>•	Su inscripción en el Registro Público de Usuarios para evitar publicidad, que está a cargo de la CONDUSEF, con la finalidad de que sus datos personales no sean utilizados para recibir publicidad o promociones de empresas de bienes o servicios. Para mayor información sobre este registro, usted puede consultar el portal de Internet de la CONDUSEF, o bien ponerse en contacto directo con ésta.
		<br>
		<br>
		<span style="color:#006699; font-weight:700">8.	Cookies y Web Beacons.</span>
		<br>
		<br>
		Le informamos que en nuestra página de Internet utilizamos cookies, web beacons y otras tecnologías a través de las cuales es posible monitorear su comportamiento como usuario de Internet, brindarle un mejor servicio y experiencia de usuario al navegar en nuestra página, así como ofrecerle nuevos productos y servicios basados en sus preferencias.
		<br>
		<br>
		Los datos personales que obtenemos de estas tecnologías de rastreo son los siguientes: horario de navegación, tiempo de navegación en nuestra página de Internet, secciones consultadas y páginas de Internet accedidas previo a la nuestra. Asimismo, le informamos que puede deshabilitar el uso de estos mecanismos siguiendo el procedimiento para desactivación, establecido para su navegador de Internet.
		<br>
		<br>
		<span style="color:#006699; font-weight:700">9.	Modificaciones al <span style="color:#006699; font-weight:700">aviso de privacidad</span>.</span>
		<br>
		<br>
		El presente <span style="color:#006699; font-weight:700">aviso de privacidad</span> puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad y políticas internas; de cambios en nuestro modelo de negocio, prácticas del mercado o por otras causas, que en su momento le serán informadas, por lo que <span style="color:#006699; font-weight:700">CREDIX</span> se reserva el derecho de modificar en cualquier momento el presente <span style="color:#006699; font-weight:700">aviso de privacidad</span>.
		<br>
		<br>
		Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente <span style="color:#006699; font-weight:700">aviso de privacidad</span>, a través de nuestra página de internet www.<span style="color:#006699; font-weight:700">credix.com.mx</span> sección “<span style="color:#006699; font-weight:700">aviso de privacidad</span>”.
		<br>
		<br>
		El procedimiento a través del cual se llevarán a cabo las notificaciones sobre cambios o actualizaciones al presente <span style="color:#006699; font-weight:700">aviso de privacidad</span> será a través de la página de internet de <span style="color:#006699; font-weight:700">CREDIX</span>, la cual contendrá en el apartado “<span style="color:#006699; font-weight:700">aviso de privacidad</span>” la fecha de la última actualización de su contenido. Asimismo, se informará a través de la página de internet las modificaciones del <span style="color:#006699; font-weight:700">aviso de privacidad</span> dentro de un plazo de diez días hábiles previos a aquél en que aplique el cambio que corresponda.
		<br>
		<br>
		En caso de que exista algún cambio sustancial en este <span style="color:#006699; font-weight:700">aviso de privacidad</span>, se le comunicará mediante cualquiera de los siguientes medios: a) página de Internet (<span style="color:#006699; font-weight:700">www.credix.com.mx</span>); b) en forma escrita; c) llamada telefónica; d) medios electrónicos; o e) por cualquier medio óptico, sonoro, visual u otro que la tecnología permita ahora o en lo futuro.
		<br>No seremos responsables si usted no recibe la notificación sobre el cambio a este <span style="color:#006699; font-weight:700">aviso de privacidad</span>, si existiere algún problema con su cuenta de correo electrónico, de transmisión de datos por Internet, o por cualquier causa no imputable a <span style="color:#006699; font-weight:700">CREDIX</span>.
		<br>
		<br>
		Por su seguridad e información, le sugerimos ampliamente que revise periódicamente el contenido de este <span style="color:#006699; font-weight:700">aviso de privacidad</span>, o bien que nos envíe cualquier solicitud de información o aclaraciones a nuestra cuenta de correo electrónico: <span style="color:#006699; font-weight:700">unes@credix.com.mx</span>.
		<br>
		<br>
		<span style="color:#006699; font-weight:700">10.	Las Previsiones Especiales para el Tratamiento de Datos Personales de Menores y Personas en Estado de Interdicción.</span>
		<br>
		<br>
		Le informamos que es de nuestro especial interés cuidar la información personal de los menores de edad y personas en estado de interdicción y capacidades diferentes en términos de ley, a través del establecimiento de medidas específicas, como son:
		<br>
		<br>
		•	La obtención del consentimiento de los padres y tutores para el tratamiento de los datos personales de este grupo de personas por medio del presente <span style="color:#006699; font-weight:700">aviso de privacidad</span>, a través de corroborar la misma con ellos, así como solicitar la acreditación del parentesco o tutoría.
		<br>•	La verificación de la autenticidad del consentimiento otorgado por los padres o tutores para el tratamiento de los datos personales de este grupo de personas, solicitando la validación del otorgamiento del consentimiento y del mecanismo para ello de los mismos con los padres y tutores.
		<br>•	La implementación y mantenimiento de medidas de seguridad más estrictas a efecto de asegurar la confidencialidad de los datos personales de este grupo de personas.
		<br>
		<br>
		Si cualquier titular de datos personales fuera menor de edad en el momento de proporcionar su información a <span style="color:#006699; font-weight:700">CREDIX</span>, este considerará que dicho titular cuenta con la autorización de sus padres o tutores para hacerlo y que ha informado a ellos de la finalidad de entrega de sus datos, salvo prueba o declaración en contrario, quedando <span style="color:#006699; font-weight:700">CREDIX</span> facultado a corroborar por cualquier medio tal autorización. El padre, madre o tutor del titular menor de edad que haya proporcionado sus datos personales sin su consentimiento, podrá solicitar que dichos datos sean cancelados de conformidad con el procedimiento contenido en el apartado 6 del presente <span style="color:#006699; font-weight:700">aviso de privacidad</span>.
		<br>
		<br>
		<span style="color:#006699; font-weight:700">11.	Información sobre el INAI.</span>
		<br>
		<br>
		Si usted considera que su derecho a la protección de sus datos personales ha sido lesionado por alguna conducta u omisión de nuestra parte, o presume alguna violación a las disposiciones previstas en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, su Reglamento y demás ordenamientos aplicables, podrá interponer su inconformidad o denuncia ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI). Para mayor información, le sugerimos visitar su página oficial de Internet www.inai.org.mx.
		<br>
		<br>
		<span style="color:#006699; font-weight:700">12.	Consentimiento.</span>
		<br>
		<br>
		El Titular de los datos personales proporcionados a <span style="color:#006699; font-weight:700">CREDIX</span>, manifiesta que ha leído el <span style="color:#006699; font-weight:700">aviso de privacidad</span> Integral para Clientes, que conoce las categorías de datos personales recabados (incluidas las categorías relativas a datos patrimoniales), así como las finalidades para el tratamiento de sus datos personales; las transferencias que <span style="color:#006699; font-weight:700">CREDIX</span> efectúa y que consiente libremente el tratamiento de sus datos personales en los términos descritos en el presente <span style="color:#006699; font-weight:700">aviso de privacidad</span>.
		<br>
		<br>
		Todo tratamiento de datos personales estará sujeto al consentimiento expreso o tácito del Titular, salvo las excepciones previstas por la LFPDPPP. De conformidad con el artículo 8 de la LFPDPPP, se entenderá que el Titular, respectivamente, consiente el tratamiento de sus datos personales de manera: (i) tácita, cuando habiéndose puesto a su disposición este <span style="color:#006699; font-weight:700">aviso de privacidad</span>, a través del Portal, no manifieste oposición alguna; y, (ii) expresa.


		</p><p></p>

                                            
                                         
                            </div>

                        </div>

                    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style>
.aviso{
    margin:3rem 0;
}
</style>