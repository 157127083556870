<template>
    <section>
        <div class="cargando" v-show="cargando">
            <div class="spinner" textosP="2">
                <Loading/>
            </div>
        </div>
        <div class="container" v-show="!cargando">
            <div class="row textosArriba">
                <div class="col-md-5">
                    <div class="titulos">
                        <h1>Simula tu crédito<b> ahora</b> </h1>
                        <div class="form" >
                          
                    
                            <div class="cantidad">
                                     <small>Selecciona o escribe el</small>
                                <div class="cantidad-header">
                                    <h4>Monto Deseado:</h4>
                                    <money class="cantidad-input-label" v-model="mCantidad" v-bind="money" ></money>
                                </div>
                                <div class="slider">
                                    <input type="range" class="rangoinp" min="500000" max="50000000" step="100000" v-model="mCantidad">
                                </div>
                            </div>
                            <div class="cantidad">
                                <div class="cantidad-header">
                                    <h4>Plazo:</h4>
                                    <money class="cantidad-input-label" v-model="mMeses" v-bind="meses" ></money>
                                </div>
                                <div class="slider">
                                    <input type="range" class="rangoinp" min="6" max="46" step="6" v-model="mMeses">
                                </div>
                            </div>
                            
                             <div class="mt-4 mb-4">
                                <label>¿Cómo es tu historial crediticio?</label>
                                <div class="d-flex cuentas" >
                                    
                                    <div class="d-flex radios">
                                        <input id="bueno" type="radio" v-model="mInteres" value=".23">
                                        <label for="bueno">Muy bueno</label>
                                    </div>
                                    <div class="d-flex radios">
                                        <input id="Regular" type="radio" v-model="mInteres" value=".27">
                                        <label for="Regular">Regular</label>
                                    </div>
                                    <div class="d-flex radios">
                                        <input id="Malo" type="radio" v-model="mInteres" value=".30">
                                        <label for="Malo">Malo</label>
                                    </div>
                                </div>
                            </div>
                            <div class="grupuinput">
                                <button @click="simular">Simular</button>
                            </div>
                            <div class="terminos">
                                <p>Al dar clic en CONTINUAR aceptas nuestro <router-link to="/aviso-de-privacidad">Aviso de privacidad</router-link> , y utilizar medios electrónicos de identificación, tales como el código de verificación.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="sec-form" v-if="showSim">
                        <h2 class="color-azul " style="font-weight: 300;font-size:20px ;">Tu simulación es:</h2>
                        
                        <div class="resultado mt-4">
                            <p>Tu credito de <b>${{formatPrice(mCantidad)}} MXN</b></p>
                            <p>a plazo de <b>{{mMeses}} Meses</b></p>
                            <p>Pagarías <b>${{resultado}}</b> por mes<sup>1</sup></p>
                            <p>Pagarías <b>${{interesFinal}} </b>de intereses por mes<sup>1</sup></p>
                            
                        </div>
                        <div class="sups">
                            <p>
                                <sup>1</sup> Sujeto a aprobación y propuesta del comité de crédito.
                            </p>
                        </div>
                        <div class="btn-sol">
                            <button>Continuar financiamiento</button>
                        </div>
                        
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="titulos2">
                        <h5> Obtén un crédito con garantía inmobiliaria por <span>hasta el 50% del valor</span> de tu casa, departamento, oficina o terreno. <sup>1</sup>  </h5> 
                        <h5>Utiliza tu bien inmueble como garantía y obtén liquidez para: </h5>
                        <div class="ps-3 bull">
                            <p><svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M14,2A8,8 0 0,0 6,10A8,8 0 0,0 14,18A8,8 0 0,0 22,10H20C20,13.32 17.32,16 14,16A6,6 0 0,1 8,10A6,6 0 0,1 14,4C14.43,4 14.86,4.05 15.27,4.14L16.88,2.54C15.96,2.18 15,2 14,2M20.59,3.58L14,10.17L11.62,7.79L10.21,9.21L14,13L22,5M4.93,5.82C3.08,7.34 2,9.61 2,12A8,8 0 0,0 10,20C10.64,20 11.27,19.92 11.88,19.77C10.12,19.38 8.5,18.5 7.17,17.29C5.22,16.25 4,14.21 4,12C4,11.7 4.03,11.41 4.07,11.11C4.03,10.74 4,10.37 4,10C4,8.56 4.32,7.13 4.93,5.82Z" />
</svg> Unificar tus deudas</p>
                            <p><svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M14,2A8,8 0 0,0 6,10A8,8 0 0,0 14,18A8,8 0 0,0 22,10H20C20,13.32 17.32,16 14,16A6,6 0 0,1 8,10A6,6 0 0,1 14,4C14.43,4 14.86,4.05 15.27,4.14L16.88,2.54C15.96,2.18 15,2 14,2M20.59,3.58L14,10.17L11.62,7.79L10.21,9.21L14,13L22,5M4.93,5.82C3.08,7.34 2,9.61 2,12A8,8 0 0,0 10,20C10.64,20 11.27,19.92 11.88,19.77C10.12,19.38 8.5,18.5 7.17,17.29C5.22,16.25 4,14.21 4,12C4,11.7 4.03,11.41 4.07,11.11C4.03,10.74 4,10.37 4,10C4,8.56 4.32,7.13 4.93,5.82Z" />
</svg> Capital de trabajo</p>
                            <p><svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M14,2A8,8 0 0,0 6,10A8,8 0 0,0 14,18A8,8 0 0,0 22,10H20C20,13.32 17.32,16 14,16A6,6 0 0,1 8,10A6,6 0 0,1 14,4C14.43,4 14.86,4.05 15.27,4.14L16.88,2.54C15.96,2.18 15,2 14,2M20.59,3.58L14,10.17L11.62,7.79L10.21,9.21L14,13L22,5M4.93,5.82C3.08,7.34 2,9.61 2,12A8,8 0 0,0 10,20C10.64,20 11.27,19.92 11.88,19.77C10.12,19.38 8.5,18.5 7.17,17.29C5.22,16.25 4,14.21 4,12C4,11.7 4.03,11.41 4.07,11.11C4.03,10.74 4,10.37 4,10C4,8.56 4.32,7.13 4.93,5.82Z" />
</svg> Aprovechar oportunidades para tu negocio</p>
                            <p><svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M14,2A8,8 0 0,0 6,10A8,8 0 0,0 14,18A8,8 0 0,0 22,10H20C20,13.32 17.32,16 14,16A6,6 0 0,1 8,10A6,6 0 0,1 14,4C14.43,4 14.86,4.05 15.27,4.14L16.88,2.54C15.96,2.18 15,2 14,2M20.59,3.58L14,10.17L11.62,7.79L10.21,9.21L14,13L22,5M4.93,5.82C3.08,7.34 2,9.61 2,12A8,8 0 0,0 10,20C10.64,20 11.27,19.92 11.88,19.77C10.12,19.38 8.5,18.5 7.17,17.29C5.22,16.25 4,14.21 4,12C4,11.7 4.03,11.41 4.07,11.11C4.03,10.74 4,10.37 4,10C4,8.56 4.32,7.13 4.93,5.82Z" />
</svg> Hacer realidad proyecto que tienes en mente</p>
                        </div>
                        <div class="sups">
                            <p>
                                <sup>1</sup>Sujeto a aprobación y propuesta del comité de crédito.
                            </p>
                        </div>
                    </div>
                </div>
                
            </div>
            
            
        </div>
        
       
    </section>

</template>
<script>
import Loading from '@/components/Loading/financiamiento.vue'
import {Money} from 'v-money'
export default {
    components:{
      Loading,Money
    },
  data() {
    return {
      mCantidad: 2000000,
      mMeses:12,
      mNombre:null,
      mRfc:'',
      mCorreo:'',
      mTel:'',
      errors:[],
      mInteres:0.30,
      mCheckGarnatia:null,
      cargando:false,
      mTipoCredito:"null",
      resultado:0,
      showSim:false,
      interesFinal:0,
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        suffix: ' MXN',
        precision: 0,
        masked: false
      },
      meses: {
       
        prefix: '',
        suffix: '  Meses',
        precision: 0,
        masked: false
      }
    }
  },
  methods: {
    formatPrice(value) {
        let val = (value/1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    mandarForm(e){
        
        window.scrollTo(0, 0);
        e.preventDefault();
        this.cargando = true
        setTimeout(() => {
            if(this.mCheckGarnatia == "no"){
                this.$router.push('/solicitud/lo-sentimos');
              return
            }else{
                this.$router.push('/solicitud/completado');
            }
        }, 8000);       
    },
    historial(){
        
        if(this.mInteres == ".30"){
            return "Malo"
        }
        if(this.mInteres == ".27"){
            return "Regular"
        }
        if(this.mInteres == ".23"){
            return "Muy bueno"
        }
    },
    simular(){
        const deseado = this.mCantidad
        const meses = this.mMeses
        const interes = parseFloat(this.mInteres) / 12
        const aPagar = (deseado*interes)/(1-(Math.pow(1 + interes,-meses)))
        this.interesFinal = this.formatPrice(parseInt(aPagar - (deseado/meses)))
        this.showSim=true
        this.resultado = this.formatPrice(parseInt(aPagar))
        
    } 
   },
   computed:{
        isComplete () {
            return (
                this.mCorreo != '' && this.mTel != '' && this.mNombre != '' && this.mCheckGarnatia != null
        )
    }
   },

   mounted(){

        if(this.$route.query.nombre){ this.mNombre = this.$route.query.nombre}
        if(this.$route.query.credito){ this.mTipoCredito = this.$route.query.credito}
        if(this.$route.query.cantidad){ 
            let cantidad = this.$route.query.cantidad;
            cantidad=cantidad.replace("$","");
            cantidad=cantidad.replace(",","");
            cantidad=cantidad.replace(",","");
            cantidad=cantidad.replace(" ","");
            cantidad=cantidad.replace(" ","");
            console.log(cantidad);
            this.mCantidad = parseInt(cantidad)
        }
   }
}
</script>
<style lang="css">
.grupuinput button:disabled{
  cursor: not-allowed;
}
.textosArriba {
  margin-top: 1rem;
}
.textosArriba .titulos h1{
  width: 100%;
}
.sups p {
  font-size: 8.5pt;
  color:rgb(177, 177, 177)
}
.cargando {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70vh;
}
.titulos2 {
  display: none;
}
.titulos {
  margin-top: 2rem;
}
.titulos sup {
  font-size: 9pt;
}
.titulos h1 {
  width: 70%;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 52px;
  color: #006699;
}
.titulos h5 {
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0.5px;
  line-height: 28px;
  color: #201f2d;
}
.titulos h5 span {
  font-weight: bold;
  color: #006699;
}
.titulos .bull svg {
  color: #006699;
}
.sec-form {
  max-width: 500px;
  margin: auto;
  width: 100%;
  transition: all 0.3s;
  padding: 40px 0px 40px 0px;
  border-radius: 16px;
}
.form .cuentas label {
  font-size: 11pt;
}
.form .cuentas .radios {
  margin-left: 12px;
  display: flex;
  flex:1;
  justify-content: center;
  align-items: center;
}
.form .cuentas .radios label {
  margin-left: 5px;
  margin-bottom: 0;
}
.form .grupoinput {
  margin-bottom: 1.4rem;
}
.form .grupoinput label {
  position: relative;
  display: block;
  background-color: #edf0ef;
  border-radius: 16px;
}
.form .grupoinput label input, .form .grupoinput label select {
  font-size: 12px;
  box-sizing: border-box;
  display: block;
  border: none;
  padding: 20px;
  width: 95%;
  margin-bottom: 20px;
  background: none;
  outline: none;
  transition: all 0.2s ease-in-out;
}
.form .grupoinput label input.rfc, .form .grupoinput label select.rfc {
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.form .grupoinput label input::placeholder, .form .grupoinput label select::placeholder {
  transition: all 0.2s ease-in-out;
  color: #999;
  font-size: 15px;
}
.form .grupoinput label input:focus, .form .grupoinput label select:focus, .form .grupoinput label input.populated, .form .grupoinput label select.populated {
  padding-top: 28px;
  padding-bottom: 12px;
}
.form .grupoinput label input:focus::placeholder, .form .grupoinput label select:focus::placeholder, .form .grupoinput label input.populated::placeholder, .form .grupoinput label select.populated::placeholder {
  color: transparent;
}
.form .grupoinput label input:focus + span, .form .grupoinput label select:focus + span, .form .grupoinput label input.populated + span, .form .grupoinput label select.populated + span {
  opacity: 1;
  top: 10px;
}
.form .grupoinput label span {
  color: #006699;
  font: 13px Helvetica, Arial, sans-serif;
  position: absolute;
  top: 0px;
  left: 20px;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
.form button {
  width: 100%;
  font-family: inherit;
  line-height: inherit;
  font-weight: 500;
  cursor: pointer;
  transition-duration: 0.1s;
  transition-property: background-color, color, border-color, opacity, box-shadow;
  transition-timing-function: ease-out;
  outline: none;
  border: 1px solid transparent;
  margin: 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px 0px;
  padding: 10px 14px;
  min-height: 40px;
  background-color: #006699;
  color: #fff;
  margin: 1rem 0;
  border-radius: 8px;
}

.btn-sol button{
  width: auto;
  font-family: inherit;
  line-height: inherit;
  font-weight: 500;
  cursor: pointer;
  transition-duration: 0.1s;
  transition-property: background-color, color, border-color, opacity, box-shadow;
  transition-timing-function: ease-out;
  outline: none;
  border: 1px solid transparent;
  margin: 0px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 12px 5px;
  padding: 10px 40px;
  min-height: 40px;
  background-color: #006699;
  color: #fff;
  margin: 1rem 0;
  border-radius: 8px;
}
.form button:disabled {
  color: #999;
  background-color: #edf0ef;
}
.form .terminos p {
  font-size: 12px;
  text-align: justify;
  color: #999;
}
.form .terminos p a {
  text-decoration: none;
  color: #006699e3;
}
.cantidad {
  width: 100%;
}
.cantidad-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cantidad-header h4 {
  font-weight: 300;
  font-size: 12pt;
}
.cantidad-header p {
  font-size: 15pt;
  font-weight: 600;
  color: #006699;
}
.cantidad .rangoinp {
  width: 100%;
  margin-bottom: 1.5rem;
  -webkit-appearance: none;
  height: 3px;
  background: #7dc3e6;
}
.cantidad .rangoinp::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid #006699;
  cursor: grab;
  background: #fff;
  box-shadow: 1px 1px 1px #006699;
}
.cantidad .rangoinp::-webkit-slider-thumb:active {
  cursor: grabbing;
}
.resultado p b{
    color:#006699;
}
@media only screen and (max-width: 768px) {
  .sec-form {
    padding: 0px 10px 40px 10px;
  }
  .textosArriba {
    margin-top: 0rem;
  }
  .textosArriba .titulos {
    margin-top: 1rem;
  }
  .textosArriba .titulos h1 {
    font-size: 20px;
    text-align: center;
    width: 100%;
  }
  .textosArriba .titulos h5, .textosArriba .titulos .bull, .textosArriba .titulos .sups {
    display: none;
  }
  .titulos2 {
    display: block;
    margin-bottom: 1rem;
  }
  .titulos2 sup {
    font-size: 9pt;
  }
  .titulos2 h5 {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.5px;
    line-height: 28px;
    text-align: justify;
    color: #201f2d;
  }
  .titulos2 h5 span {
    font-weight: bold;
    color: #006699;
  }
  .titulos2 .bull {
    margin-top: 15px;
  }
  .titulos2 .bull p {
    font-size: 12px;
    margin-bottom: 0;
  }
  .titulos2 .bull svg {
    color: #006699;
  }
  .titulos2 .sups p {
    margin-top: 10px;
    font-size: 10px;
  }
}

</style>