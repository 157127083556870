import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ScrollAnimation from './directives/scrollanimation'
import Styles from './assets/css/styles.css'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import { VueMaskDirective } from 'v-mask'
console.log("process.env.BASE_URL:", process.env.BASE_URL)
Vue.config.productionTip = false
Vue.config.devtools = true;
Vue.directive('scrollanimation',ScrollAnimation)
Vue.directive('mask', VueMaskDirective);
new Vue({
  router,
  store,
  Styles,
  render: h => h(App)
}).$mount('#app')
