<template>
<div>
  <section class="py-5 servicio">
      <div class="container">
          <div class="row">
              <div class="col-md-6">
                  <div class="servicio-contenido">
                      <div>
                        <div class="servicio-titulo">
                            <h1>Fideicomiso de Garantía para Desarrollos Inmobiliarios</h1>
                        </div>
                        <div class="servicio-textos">
                            <h1>La manera más ágil, segura y transparente de asociarse y desarrollar</h1>
                            
                            <h5 class="mt-4">El favorito de los desarrolladores inmobiliarios.</h5>
                        </div>
                      </div> 
                  </div>
                  
              </div>
              <div class="col-md-6">
                  <div class="frame-video">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/K5OUcm8zrA8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>  
                  </div>
              </div>
          </div>
      </div>
  </section>
  <section class="requisitos py-5">
      <div class="container">
          <div class="row">
              <div class="col-md-6">
                  <div class="requisitos-titulo">
                    <h1>Requisitos </h1>
                  </div>
              </div>
              <div class="col-md-6">
                   <div class="item-req">
                    <div class="icon">
                        <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2,4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z" />
                        </svg>
                    </div>
                    <h6 class="ms-3"> Tener un proyecto maestro de desarrollo inmobiliario de al menos 100 unidades vendibles a llevar a cabo en el mediano plazo. </h6>
                  </div>
                  <div class="item-req">
                    <div class="icon">
                        <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2,4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z" />
                        </svg>
                    </div>
                    <h6 class="ms-3"> Las partes involucradas deberán aportar el capital y el terreno al patrimonio del fideicomiso.</h6>
                  </div>
                  <div class="item-req">
                    <div class="icon">
                        <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2,4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z" />
                        </svg>
                    </div>
                    <h6 class="ms-3"> Llenar y firmar solicitud de fideicomiso de garantía de crédito </h6>
                  </div>
                  
              </div>
          </div>
      </div>
  </section>
  <section class="financiamiento ">
      <div class="financiamiento-texto py-4">
        <h1>Aplica ahora mismo</h1>
       <p>Fideicomiso exclusivo para el desarrollo <br> de al menos de 100 unidades vendibles</p>
      </div>
      <div class="financiamiento-form py-4">
          <h3>Solicita tu <strong>Fideicomiso de Garatía para Desarrollo Inmobiliario</strong> </h3>
          <div class="form">
              <div class="input-form">
                  <label for="">Selecciona el tipo de <br> desarrollo inmobiliario a realizar</label>
                  <select v-model="mDesarrollo">
                    <option value="null">Selecciona:</option>
                      <option value="vertical">Vertical</option>
                      <option value="Horizontal">Horizontal</option>
                      <option value="Comercial">Comercial</option>
                      <option value="Mixto">Mixto</option>
                      <option value="Otro">Otro</option>
                  </select>
              </div>
              <div class="input-form">
                  <input type="number" min="100" placeholder="Total de unidades a desarrollar" v-model="mUnidades">
              </div>
              <div class="input-form">
                  <input type="text" placeholder="Escribe tu nombre" v-model="mNombre">
              </div>
              <div class="btn">
                  <button class="btn-credix" @click="aplicar">
                        <span class="ml-2"> Solicitar Fideicomiso Inmobiliario</span>
                        <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                        </svg>
                  </button>
              </div>
              <div class="menos" v-if="isMenos">
                <div class="alert">
                  <b>Lo sentimos</b> la cantidad mínima es de <br> <b> 100 unidades vendibles</b></div>
              </div>
          </div>
      </div>
  </section>
  <section>
      <div class="container">
        <div class="row ">
            <div class="col-md-12" >
                <div class="credixpq">
                    <div class="titulos-iniciales ">
                      <div class="titulo">
                          <h1><span>Características</span> </h1>
                      </div>
                    </div>
                    <div class="carac">
                        <div class="item-caract">
                           <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                                <g id="XMLID_1160_">
                                  <path id="XMLID_1172_" class="st0" d="M98.87,33.92c0,0-36.88,20.96-21.06,43.81c0,0-18.74-14.14-10.4-26.39   c0,0,4.43-8.2,31.69-17.57"/>
                                  <path id="XMLID_1165_" class="st0" d="M20.27,96.31c0,0,19.52-37.67-6.2-48.21c0,0,23.41,1.66,23.09,16.47c0,0,0.75,9.29-17.01,32"/>
                                  <path id="XMLID_1164_" class="st0" d="M0.9,34.54c25.97,12.49,34.89,9.78,34.89,9.78c14.55-2.85,11.15-26.08,11.15-26.08   C42.14,45.61,1.17,34.59,1.17,34.59"/>
                                  <path id="XMLID_1162_" class="st0" d="M83.47,98.72c0,0-27.26-32.51-46.89-12.83c0,0,10.53-20.98,24.09-14.98   c0,0,8.86,2.89,22.98,28.01"/>
                                  <path id="XMLID_1161_" class="st0" d="M50.51,1.35c0,0,5.56,42.06,32.68,35.99c0,0-20.15,12.04-28.36-0.29c0,0-5.93-7.2-4.37-35.97   "/>
                                </g>
                            </svg>
                            <span>La aportación y reversión de los bienes inmuebles al patrimonio del fideicomiso no causa pago de impuestos (Traslado de Dominio).</span>
                        </div>
                        
                        <div class="item-caract">
                           <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                                <g id="XMLID_1160_">
                                  <path id="XMLID_1172_" class="st0" d="M98.87,33.92c0,0-36.88,20.96-21.06,43.81c0,0-18.74-14.14-10.4-26.39   c0,0,4.43-8.2,31.69-17.57"/>
                                  <path id="XMLID_1165_" class="st0" d="M20.27,96.31c0,0,19.52-37.67-6.2-48.21c0,0,23.41,1.66,23.09,16.47c0,0,0.75,9.29-17.01,32"/>
                                  <path id="XMLID_1164_" class="st0" d="M0.9,34.54c25.97,12.49,34.89,9.78,34.89,9.78c14.55-2.85,11.15-26.08,11.15-26.08   C42.14,45.61,1.17,34.59,1.17,34.59"/>
                                  <path id="XMLID_1162_" class="st0" d="M83.47,98.72c0,0-27.26-32.51-46.89-12.83c0,0,10.53-20.98,24.09-14.98   c0,0,8.86,2.89,22.98,28.01"/>
                                  <path id="XMLID_1161_" class="st0" d="M50.51,1.35c0,0,5.56,42.06,32.68,35.99c0,0-20.15,12.04-28.36-0.29c0,0-5.93-7.2-4.37-35.97   "/>
                                </g>
                            </svg>
                            <span>Es el vehículo idóneo para la inversión en todo tipo de desarrollos inmobiliarios.</span>
                        </div>
                        <div class="item-caract">
                           <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                                <g id="XMLID_1160_">
                                  <path id="XMLID_1172_" class="st0" d="M98.87,33.92c0,0-36.88,20.96-21.06,43.81c0,0-18.74-14.14-10.4-26.39   c0,0,4.43-8.2,31.69-17.57"/>
                                  <path id="XMLID_1165_" class="st0" d="M20.27,96.31c0,0,19.52-37.67-6.2-48.21c0,0,23.41,1.66,23.09,16.47c0,0,0.75,9.29-17.01,32"/>
                                  <path id="XMLID_1164_" class="st0" d="M0.9,34.54c25.97,12.49,34.89,9.78,34.89,9.78c14.55-2.85,11.15-26.08,11.15-26.08   C42.14,45.61,1.17,34.59,1.17,34.59"/>
                                  <path id="XMLID_1162_" class="st0" d="M83.47,98.72c0,0-27.26-32.51-46.89-12.83c0,0,10.53-20.98,24.09-14.98   c0,0,8.86,2.89,22.98,28.01"/>
                                  <path id="XMLID_1161_" class="st0" d="M50.51,1.35c0,0,5.56,42.06,32.68,35.99c0,0-20.15,12.04-28.36-0.29c0,0-5.93-7.2-4.37-35.97   "/>
                                </g>
                            </svg>
                            <span>Es ideal para crear y desarrollar en bienes raíces con capital de terceras personas.</span>
                        </div>
                        
                        <div class="item-caract">
                           <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                              <g id="XMLID_1160_">
                                <path id="XMLID_1172_" class="st0" d="M98.87,33.92c0,0-36.88,20.96-21.06,43.81c0,0-18.74-14.14-10.4-26.39   c0,0,4.43-8.2,31.69-17.57"/>
                                <path id="XMLID_1165_" class="st0" d="M20.27,96.31c0,0,19.52-37.67-6.2-48.21c0,0,23.41,1.66,23.09,16.47c0,0,0.75,9.29-17.01,32"/>
                                <path id="XMLID_1164_" class="st0" d="M0.9,34.54c25.97,12.49,34.89,9.78,34.89,9.78c14.55-2.85,11.15-26.08,11.15-26.08   C42.14,45.61,1.17,34.59,1.17,34.59"/>
                                <path id="XMLID_1162_" class="st0" d="M83.47,98.72c0,0-27.26-32.51-46.89-12.83c0,0,10.53-20.98,24.09-14.98   c0,0,8.86,2.89,22.98,28.01"/>
                                <path id="XMLID_1161_" class="st0" d="M50.51,1.35c0,0,5.56,42.06,32.68,35.99c0,0-20.15,12.04-28.36-0.29c0,0-5.93-7.2-4.37-35.97   "/>
                              </g>
                          </svg>
                            <span>Genera confianza a los inversionistas que participan en el proyecto.</span>
                        </div>
                        <div class="item-caract">
                           <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                                <g id="XMLID_1160_">
                                  <path id="XMLID_1172_" class="st0" d="M98.87,33.92c0,0-36.88,20.96-21.06,43.81c0,0-18.74-14.14-10.4-26.39   c0,0,4.43-8.2,31.69-17.57"/>
                                  <path id="XMLID_1165_" class="st0" d="M20.27,96.31c0,0,19.52-37.67-6.2-48.21c0,0,23.41,1.66,23.09,16.47c0,0,0.75,9.29-17.01,32"/>
                                  <path id="XMLID_1164_" class="st0" d="M0.9,34.54c25.97,12.49,34.89,9.78,34.89,9.78c14.55-2.85,11.15-26.08,11.15-26.08   C42.14,45.61,1.17,34.59,1.17,34.59"/>
                                  <path id="XMLID_1162_" class="st0" d="M83.47,98.72c0,0-27.26-32.51-46.89-12.83c0,0,10.53-20.98,24.09-14.98   c0,0,8.86,2.89,22.98,28.01"/>
                                  <path id="XMLID_1161_" class="st0" d="M50.51,1.35c0,0,5.56,42.06,32.68,35.99c0,0-20.15,12.04-28.36-0.29c0,0-5.93-7.2-4.37-35.97   "/>
                                </g>
                            </svg>
                            <span>El patrimonio queda protegido de embargos o en caso de fallecimiento</span>
                        </div>
                        
                        
                        <div class="item-caract">
                           <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                                <g id="XMLID_1160_">
                                  <path id="XMLID_1172_" class="st0" d="M98.87,33.92c0,0-36.88,20.96-21.06,43.81c0,0-18.74-14.14-10.4-26.39   c0,0,4.43-8.2,31.69-17.57"/>
                                  <path id="XMLID_1165_" class="st0" d="M20.27,96.31c0,0,19.52-37.67-6.2-48.21c0,0,23.41,1.66,23.09,16.47c0,0,0.75,9.29-17.01,32"/>
                                  <path id="XMLID_1164_" class="st0" d="M0.9,34.54c25.97,12.49,34.89,9.78,34.89,9.78c14.55-2.85,11.15-26.08,11.15-26.08   C42.14,45.61,1.17,34.59,1.17,34.59"/>
                                  <path id="XMLID_1162_" class="st0" d="M83.47,98.72c0,0-27.26-32.51-46.89-12.83c0,0,10.53-20.98,24.09-14.98   c0,0,8.86,2.89,22.98,28.01"/>
                                  <path id="XMLID_1161_" class="st0" d="M50.51,1.35c0,0,5.56,42.06,32.68,35.99c0,0-20.15,12.04-28.36-0.29c0,0-5.93-7.2-4.37-35.97   "/>
                                </g>
                            </svg>
                            <span>Brinda certeza jurídica a todas las partes. </span>
                        </div>
                        <div class="item-caract">
                           <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                                <g id="XMLID_1160_">
                                  <path id="XMLID_1172_" class="st0" d="M98.87,33.92c0,0-36.88,20.96-21.06,43.81c0,0-18.74-14.14-10.4-26.39   c0,0,4.43-8.2,31.69-17.57"/>
                                  <path id="XMLID_1165_" class="st0" d="M20.27,96.31c0,0,19.52-37.67-6.2-48.21c0,0,23.41,1.66,23.09,16.47c0,0,0.75,9.29-17.01,32"/>
                                  <path id="XMLID_1164_" class="st0" d="M0.9,34.54c25.97,12.49,34.89,9.78,34.89,9.78c14.55-2.85,11.15-26.08,11.15-26.08   C42.14,45.61,1.17,34.59,1.17,34.59"/>
                                  <path id="XMLID_1162_" class="st0" d="M83.47,98.72c0,0-27.26-32.51-46.89-12.83c0,0,10.53-20.98,24.09-14.98   c0,0,8.86,2.89,22.98,28.01"/>
                                  <path id="XMLID_1161_" class="st0" d="M50.51,1.35c0,0,5.56,42.06,32.68,35.99c0,0-20.15,12.04-28.36-0.29c0,0-5.93-7.2-4.37-35.97   "/>
                                </g>
                            </svg>
                            <span>Menores costos de ejecución en proyectos de construcción.</span>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
      </div>
        
  </section>
  <section class="py-4 container" ><hr></section>
  <section class="faqs my-5">
      <div class="container">
          <div class="titulos-iniciales ">
            <div class="titulo">
                <h1>Preguntas <span>frecuentes</span> </h1>
            </div>
          </div>
          <div class="accordion">
              <Pregunta>
                <template v-slot:title>
                    <span class="accordion-title">Gastos de originación</span>
                    <span class="icon" aria-hidden="false"></span>
                </template>
                <template v-slot:content>
                <p>
                    <ul>
                        <li>Honorarios fiduciarios (monto variable calculado a partir de las obligaciones a garantizar, ubicación geográfica, número de unidades vendibles, precio de unidades vendibles y plazo del proyecto inmobiliario). </li>
                        <li>Honorarios notariales y gastos de registro (monto variable calculado a partir de la ubicación geográfica del desarrollo inmobiliario).</li>
                    </ul>
                </p>
                </template>
              </Pregunta>
            <Pregunta>
                <template v-slot:title>
                    <span class="accordion-title">Tiempo de trámite</span>
                    <span class="icon" aria-hidden="false"></span>
                </template>
                <template v-slot:content>
                <p>
                   30 días tiempo aproximado a partir de contar con toda la documentación requerida para la elaboración del fideicomiso.
                </p>
                </template>
            </Pregunta>
           
            
        </div>
      </div>
  </section>
  </div>
</template>

<script>
import Pregunta from "@/components/otros/Accordion";

export default {
  components:{Pregunta},
    methods:{
        toggleAccordion(event){
          if( event.path[1].ariaExpanded == "false"){
               event.path[1].ariaExpanded = "true"
          } else{
               event.path[1].ariaExpanded = "false"
          }
        },
        aplicar(){
            if(parseInt(this.mUnidades) >= 100){
            if(this.mUnidades == null && this.mNombre ==""){
                this.alert = true;
                return
            }
            this.$router.push({path:'/solicitar-fideicomiso-de-garantia-desarrollo-inmobiliario',query:{nombre:this.mNombre,cantidad:this.mUnidades,des:this.mDesarrollo}})
            }else{
              this.isMenos = true;
            }
        }
      
    },
    data(){
        return {
            mUnidades:null,
            mNombre:"",
            mDesarrollo:null,
            alert:false,
            isMenos:false
        }
    }
}
</script>

<style lang="css">
.credixpq {
  margin-top: 2rem;
}
.credixpq h1 {
  margin-bottom: 1rem;
  color: #006699;
}
.credixpq .carac {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.credixpq .carac .item-caract {
  margin-bottom: 0.5rem;
  color: #171f2a;
  width: 50%;
}
.credixpq .carac .item-caract span {
  margin-left: 0.8rem;
}
.item-req {
  display: flex;
  color: #fff;
  margin: 1rem 0;
  align-items: center;
}
.item-req h6 {
   margin: 0 0 0 15px;
  font-size: 1rem;
}
.servicio-contenido {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  text-align: left;
}
.servicio h1 {
  color: #006699;
  font-size: 2rem;
}
.servicio-titulo h1 {
  font-size: 11pt;
  font-weight: 500;
}
.servicio-textos {
  color: #006699;
}
.frame-video iframe {
  border-radius: 20px;
  box-shadow: 0px 0px 8px 1px #006699;
}
.requisitos {
  background-color: #006699;
}
.requisitos h1 {
  color: #fff;
}
.requisitos-titulo {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.financiamiento {
  display: flex;
  color: #fff;
  flex-wrap: wrap;
  text-align: center;
}
.financiamiento-texto {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 50%;
  background-color: #171f2a;
  padding: 0 3rem;
}
.financiamiento-form {
  color: #006699;
  width: 50%;
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.financiamiento-form .form {
  width: 400px;
  margin: 0 auto;
}
.financiamiento-form .form .btn {
  width: 100%;
  padding: 0;
}
.financiamiento-form .form .btn-credix {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1.2rem 1.5rem;
}
.financiamiento-form .input-form {
  display: flex;
  margin-bottom: 1rem;
  flex-direction: column;
}
.financiamiento-form .input-form label {
  margin-bottom: 10px;
}
.financiamiento-form .input-form .cantidad {
  padding: 2rem 4rem;
  border-radius: 15px;
  font-size: 20px;
  text-align: center;
  border: 1px solid rgba(112, 112, 112, 0.436);
}
.financiamiento-form .input-form .cantidad :focus-visible {
  outline-color: #006699;
  border: none;
}
.financiamiento-form .input-form input {
  padding: 1rem 0.8rem;
  font-size: 10pt;
  border-radius: 15px;
  outline: none;
  border: 1px solid rgba(112, 112, 112, 0.436);
}
.financiamiento-form .input-form select {
  padding: 1rem 0.8rem;
  font-size: 10pt;
  border-radius: 15px;
  outline: none;
  border: 1px solid rgba(112, 112, 112, 0.436);
}
.financiamiento-form .input-form input :focus-visible {
  outline-color: #006699;
  border: none;
}
@media (max-width: 768px) {
  .financiamiento-texto {
    width: 100%;
    background-color: #171f2a;
    padding: 0 3rem;
  }
  .financiamiento-form {
    width: 100%;
  }
  .financiamiento-form .form {
    width: 400px;
    margin: 0 auto;
  }
  .financiamiento-form .input-form {
    display: flex;
    flex-direction: column;
  }
  .financiamiento-form .input-form .cantidad {
    padding: 2rem 4rem;
    border-radius: 3px;
    font-size: 20px;
    text-align: center;
  }
}
.bkg-azul {
  background: #006699;
  color: #fff;
}

</style>